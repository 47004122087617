import App from "./App";
import { AuthContext } from "./Contexts/Auth.context.js";
function RootApp() {
  return (
    <AuthContext>
      <App />
    </AuthContext>
  );
}

export default RootApp;
