import { createContext, useContext, useEffect, useState } from "react";
import Papa from "papaparse";
import ChangePassword from "../Admin/Pages/ChangePassword";
const adminContext = createContext();

export function AuthContext({ children }) {
  const [token, settoken] = useState();
  const [submittedTools, setSubmittedTools] = useState();
  const [allMembers, setAllMembers] = useState();

  const setTokenCookie = (token) => {
    // Set a cookie with a name "token" and value "yourTokenValue"
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 1 * 60 * 60 * 1000); // 2 hours from now

    const expires = "expires=" + expirationDate.toUTCString();

    document.cookie = `token=${token}; ${expires}; `;
  };

  const getCookie = () => {
    const cookies = document.cookie.split("; ");

    const cookie = cookies.find((c) => c.startsWith("token="));

    if (cookie) {
      return cookie.split("=")[1];
    }

    return null;
  };

  const handleSignin = async (email, password) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}api/signin`;
      const response = await fetch(url, {
        method: "Post",
        body: JSON.stringify({ email: email, password: password }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);
      if (!data.status) {
        throw new Error("Invalid Credentials");
      } else {
        settoken(data.res);
        setTokenCookie(data.res);
        return { status: true };
      }
    } catch (err) {
      return { status: false, err: err.message };
    }
  };

  const handleSignout = () => {
    settoken(null);
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  };

  const convertToCSV = (json) => {
    const csv = Papa.unparse(json, { newline: "\n" });
    const blob = new Blob([csv]);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob, { type: "text/plain" });
    a.download = "subscribers.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return csv;
  };

  const getSubscribers = async () => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/getsubs";
      const response = await fetch(url, {
        headers: {
          Authorization: token,
        },
      });
      const data = await response.json();
      console.log(data);
      if (!data.status) {
        throw new Error(data.res);
      } else if (data.res.length) {
        console.log("data ", data);
        convertToCSV(data.res);
      } else {
        throw new Error("No subscribers");
      }
    } catch (err) {
      return err.message;
    }
  };

  const ChangePassword = async (newp) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/resetpassword";
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({ newPassword: newp }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err.message);
      return { status: false, res: err.message };
    }
  };

  const addMember = async (name, email, pass, user) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/signup";
      const response = await fetch(url, {
        method: "Post",
        body: JSON.stringify({
          email: email,
          password: pass,
          name: name,
          type: user,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = await response.json();
      setAllMembers([...allMembers, { ...data.res }]);
      return data;
    } catch (err) {
      console.log(err.message);
      return { status: false, res: err.message };
    }
  };

  const acceptSubmittedTool = async (tool) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/acceptTool";
      const response = await fetch(url, {
        method: "Post",
        body: JSON.stringify({
          tool: tool,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      if (data.status) setSubmittedTools([...data.res]);
    } catch (err) {
      console.log(err.message);
      return { status: false, res: err.message };
    }
  };

  const deleteSubmittedTool = async (id) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/deleteTool";
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      if (data.status) setSubmittedTools([...data.res]);
    } catch (err) {
      console.log(err.message);
      return { status: false, res: err.message };
    }
  };

  const handleUpdateTool = (tool) => {
    const arr = submittedTools.map((t) => {
      if (t.id == tool.id) {
        t.toolName = tool.toolName;
        t.toolUrl = tool.toolUrl;
        t.category = tool.category;
        t.description = tool.description;
      }
      return t;
    });
    setSubmittedTools([...arr]);
  };

  const getSubmittedTools = async (tokenval) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/getSubmittedTools";
      const response = await fetch(url, {
        headers: {
          Authorization: tokenval,
        },
      });
      const data = await response.json();
      setSubmittedTools([...data.res]);
    } catch (err) {
      console.log("error while fetching submissions: " + err.message);
    }
  };

  const getMembers = async (tokenval) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/getMembers";
      const response = await fetch(url, {
        headers: {
          Authorization: tokenval,
        },
      });
      const data = await response.json();
      if (data.status) setAllMembers([...data.res]);
    } catch (err) {
      console.log("error while fetching members: " + err.message);
    }
  };

  const updateMemberPermissions = async (id, type) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/updatePermission";
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          id: id,
          type: type,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log("error while fetching members: " + err.message);
    }
  };

  useEffect(() => {
    const cookieValue = getCookie();
    if (cookieValue) {
      settoken(cookieValue);
      getSubmittedTools(cookieValue);
      getMembers(cookieValue);
    }
  }, []);

  return (
    <adminContext.Provider
      value={{
        token,
        handleSignin,
        handleSignout,
        getSubscribers,
        ChangePassword,
        addMember,
        submittedTools,
        deleteSubmittedTool,
        acceptSubmittedTool,
        handleUpdateTool,
        allMembers,
        updateMemberPermissions,
        setAllMembers,
      }}
    >
      {children}
    </adminContext.Provider>
  );
}

function useAuthValue() {
  const res = useContext(adminContext);
  return res;
}

export default useAuthValue;
