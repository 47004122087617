import { useContext, createContext, useState, useEffect } from "react";
import useAuthValue from "./Auth.context";
const ToolsContext = createContext();

function ProductsContext({ children }) {
  // unfiltered fetched data
  const [FetchedData, setFetchedData] = useState([]);

  //data on which search and filter operatios are done
  const [ProductsData, setProductsData] = useState([]);
  // const [searchFetchedData, setSearchFetchedData] = useState([]);

  //data on which search and filter operatios are done
  // const [searchProductsData, setSearchProductsData] = useState([]);

  // cartegories to filter
  const [filterCategory, setCategory] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setError] = useState();

  const [featuredTools, setFeaturedTools] = useState([]);
  const [search, setSearch] = useState("");
  const { token } = useAuthValue();

  const handleSearch = async (searchedTerm) => {
    try {
      setIsLoading(true);
      // setSearch(searchedTerm);
      const url =
        process.env.REACT_APP_API_BASE_URL +
        `api/GetSearchedTools?searchTerm=${searchedTerm}`;
      const response = await fetch(url, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ next: false }),
      });
      let dataFetched = await response.json();
      // console.log("searched item: ", dataFetched);
      setIsLoading(false);
      if (dataFetched.products.length > 0) {
        setProductsData([...dataFetched.products]);
        setFetchedData([...dataFetched.products]);
        return { err: false, status: true };
      } else {
        console.log("No searhced items  found");
        return { err: false, status: false };
      }
    } catch (error) {
      console.log("Error while fetching searched data", error);
      return { err: true, msg: error.message };
    }

    //earlier search algo
    // const value = e.currentTarget.value.trim().toLowerCase();
    // const searched = FetchedData.filter((p) => {
    //   const str = (
    //     p["Tool Name"] +
    //     " " +
    //     p["Category"] +
    //     " " +
    //     p["Description"]
    //   ).toLowerCase();
    //   const pattern = new RegExp(value);
    //   if (pattern.test(str)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // setProductsData([...searched]);
  };

  const handleCategory = (value) => {
    const found = filterCategory.findIndex((c) => c === value);
    if (found === -1) {
      filterCategory.push(value);
      setCategory([...filterCategory]);
    } else {
      filterCategory.splice(found, 1);
      setCategory([...filterCategory]);
    }
  };

  const handleVoteCount = async (id) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/addVote";
      const response = await fetch(url, {
        method: "Post",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      return { status: true, res: "Liked" };
    } catch (err) {
      return { status: false, res: err.message };
    }
  };

  const submitTool = async (tool) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/submitTool";
      const response = await fetch(url, {
        method: "Post",
        body: JSON.stringify({
          tool: tool,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      return { status: true, res: "Tool Submitted" };
    } catch (err) {
      return { status: false, res: err.message };
    }
  };

  const addFeaturedTool = async (tool) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/addFeaturedTool";
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          tool: tool,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      if (data.status) {
        setFeaturedTools([...featuredTools, data.res]);
        return { status: true };
      } else {
        throw new Error("Permission Denied");
      }
    } catch (err) {
      console.log(err.message);
      return { status: false, res: err.message };
    }
  };

  const removeFeaturedTool = async (id) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/removeFeaturedTool";
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      if (data.status) {
        const arr = featuredTools.filter((t) => t.id !== id);
        setFeaturedTools([...arr]);
        return { status: true };
      } else {
        throw new Error("Permission Denied");
      }
    } catch (err) {
      console.log(err.message);
      return { status: false, res: err.message };
    }
  };

  const fetchFeaturedTools = async () => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/fetchFeaturedTool";
      const response = await fetch(url);
      const data = await response.json();
      setFeaturedTools([...data.res]);
    } catch (err) {
      console.log("Not able to fetch Featured Products");
    }
  };

  const handleSearchTool = async (name) => {
    try {
      const url =
        process.env.REACT_APP_API_BASE_URL + `api/getToolByName?name=${name}`;
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (err) {
      console.log("Not able to fetch Featured Products");
    }
  };
  const handleCategoryFeatured = async (id) => {
    try {
      const url = process.env.REACT_APP_API_BASE_URL + `api/handleFeatured`;
      const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log("Not able to fetch Featured Products");
    }
  };

  async function fetchData() {
    try {
      setIsLoading(true);
      setSearch("");
      // const url = process.env.REACT_APP_API_BASE_URL + "api/GetProducts";
      const url = process.env.REACT_APP_API_BASE_URL + "api/GetLimitedTools";
      const response = await fetch(url, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ next: false }),
      });
      let dataFetched = await response.json();
      const { lastVisible } = dataFetched;
      const lastId =
        lastVisible._key.path.segments[
          lastVisible._key.path.segments.length - 1
        ];
      localStorage.setItem("lastId", lastId);
      const data = dataFetched.products;
      setProductsData([...data]);
      setFetchedData([...data]);
      setError(null);
      fetchFeaturedTools();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    return () => {
      localStorage.removeItem("lastId");
    };
  }, []);

  const LoadMoreProducts = async () => {
    const lastId = localStorage.getItem("lastId");
    console.log("lastid: ", lastId);
    try {
      const url = process.env.REACT_APP_API_BASE_URL + "api/GetLimitedTools";
      // console.log("one");
      const response = await fetch(url, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ next: true, last: lastId }),
      });
      // console.log("two");
      const dataFetched = await response.json();
      // console.log("datafetched", dataFetched);
      const { lastVisible } = dataFetched;
      const newlastId =
        lastVisible._key.path.segments[
          lastVisible._key.path.segments.length - 1
        ];
      localStorage.setItem("lastId", newlastId);

      // console.log("dataFetched", dataFetched);
      const data = dataFetched.products;
      setProductsData([...ProductsData, ...data]);
      setFetchedData([...FetchedData, ...data]);
    } catch (err) {
      console.log("error while loading more", err);
      setError(err.message);
    }
  };

  return (
    <ToolsContext.Provider
      value={{
        LoadMoreProducts,
        ProductsData,
        setProductsData,
        filterCategory,
        handleCategory,
        handleSearch,
        isLoading,
        isError,
        handleVoteCount,
        submitTool,
        addFeaturedTool,
        featuredTools,
        removeFeaturedTool,
        handleSearchTool,
        handleCategoryFeatured,
        search,
        setSearch,
        fetchData,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
}

function useProductValue() {
  const value = useContext(ToolsContext);
  return value;
}

export default useProductValue;
export { ProductsContext };
