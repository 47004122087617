import { NavLink } from "react-router-dom";
import style from "../Styles/Error.module.css";

function Error() {
  return (
    <div className={style.container}>
      <img
        src="https://www.freepnglogos.com/uploads/dog-png/bow-wow-gourmet-dog-treats-are-healthy-natural-low-4.png"
        alt="error-pic"
      />
      <h1 className="my-3">Oops... Something went wrong </h1>
      <button className={style.btn}>
        <NavLink to="/" replace={true}>
          Go to homepage
        </NavLink>
      </button>
    </div>
  );
}

export default Error;
