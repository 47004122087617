import { NavLink } from "react-router-dom";
import style from "../Styles/Error.module.css";

function AdminError() {
  return (
    <div className={`p-3 w-75 text-center ${style.container}`}>
      <img
        src="https://cdn-icons-png.flaticon.com/128/6358/6358087.png"
        alt="error-pic"
      />
      <h1 className="my-3">Oops... Something went wrong </h1>
      <button className={style.btn}>
        <NavLink to="/admin" replace={true}>
          Go to homepage
        </NavLink>
      </button>
    </div>
  );
}

export default AdminError;
