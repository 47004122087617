import React from "react";
import { Link, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Style from "../Styles/navbar.module.css";
import useProductValue from "../Contexts/Product.context";

function Navbar() {
  const inputRef = React.useRef();
  const inputRef2 = React.useRef();
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const toolNameRef = React.useRef();
  const toolUrlRef = React.useRef();
  const CategoryRef = React.useRef();
  const pricingRef = React.useRef();
  const DescriptionRef = React.useRef();
  const { submitTool } = useProductValue();
  const { fetchData } = useProductValue();

  const clearRefs = () => {
    nameRef.current.value = "";
    emailRef.current.value = "";
    toolNameRef.current.value = "";
    toolUrlRef.current.value = "";
    DescriptionRef.current.value = "";
    CategoryRef.current.value = "";
  };
  const handleSubmitTool = async (e) => {
    e.preventDefault();
    try {
      const tool = {
        name: nameRef.current.value.trim(),
        email: emailRef.current.value.trim(),
        toolName: toolNameRef.current.value.trim(),
        toolUrl: toolUrlRef.current.value.trim(),
        category: CategoryRef.current.value.trim(),
        pricing: pricingRef.current.value,
        description: DescriptionRef.current.value.trim(),
      };
      console.log(tool);
      const response = await submitTool(tool);
      clearRefs();
      toast.success("Tool submitted successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const email = inputRef.current.value || inputRef2.current.value;
    if (email) {
      //async operation
      try {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}api/subscribe`, {
          method: "Post",
          body: JSON.stringify({ email: email }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Thank you for subscribing");
      } catch (err) {
        toast.error("Server Error");
      }
      inputRef.current.value = "";
      inputRef2.current.value = "";
    } else {
      toast.info("Please enter your email to subscribe");
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-body-tertiary mb-5"
        style={{ fontWeight: 500 }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" onClick={fetchData}>
            Productfynder
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`${Style.smallsubscribeDiv}`}>
            <form
              className={`d-flex mx-auto w-md-75 ${Style.smallsubscribe}`}
              role="search"
            >
              <input
                className={`me-2 ${Style.smallsubsInput}`}
                type="search"
                placeholder="Please enter your email id"
                aria-label="Search"
                ref={inputRef2}
              />
              <button
                className={`btn btn-dark ${Style.btn}`}
                type="submit"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </form>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                  onClick={fetchData}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={`nav-link active ${Style.leftSubmit}`}
                  aria-current="page"
                  to="/"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Submit a Tool
                </Link>
                <div
                  class="modal fade "
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                          Submit Your Tool
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <form className="form" onSubmit={handleSubmitTool}>
                          <div className="form mb-3">
                            <label for="floatingName">Your Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="floatingName"
                              placeholder=""
                              ref={nameRef}
                              required
                            />
                          </div>
                          <div className="form mb-3">
                            <label for="floatingEmail">Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              id="floatingEmail"
                              placeholder="xyz@example.com"
                              ref={emailRef}
                            />
                          </div>
                          <div className="form mb-3">
                            <label for="toolName">Tool Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="toolName"
                              placeholder="your tool name"
                              ref={toolNameRef}
                            />
                          </div>
                          <div className="form mb-3">
                            <label for="floatingURL">Tool URL</label>
                            <input
                              type="url"
                              className="form-control"
                              id="floatingURL"
                              placeholder="your tool's URL"
                              ref={toolUrlRef}
                            />
                          </div>
                          <div className="form mb-3">
                            <label for="floatingtags">Tool Category</label>
                            <input
                              type="text"
                              className="form-control"
                              id="floatingtags"
                              placeholder="AI Generator, Finance, Gaming , etc"
                              ref={CategoryRef}
                            />
                          </div>
                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            ref={pricingRef}
                          >
                            <option value="Paid">Paid</option>
                            <option value="Free Trial">Free Trial</option>
                            <option value="Free">Free</option>
                          </select>

                          <div className="input-group mb-3">
                            <span className="input-group-text">
                              Tool Description
                            </span>
                            <textarea
                              className="form-control"
                              aria-label="With textarea"
                              placeholder="Write about the features of your tool"
                              style={{ height: "200px" }}
                              ref={DescriptionRef}
                            ></textarea>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className={`${Style.subscribeDiv}`}>
              <form className={`d-flex ${Style.subscribe}`} role="search">
                <input
                  className={`me-2 ${Style.subsInput}`}
                  type="search"
                  placeholder="Please enter your email id"
                  aria-label="Search"
                  ref={inputRef}
                />
                <button
                  className={`btn btn-dark ${Style.btn}`}
                  type="submit"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </form>
              <Link
                className="nav-link active"
                aria-current="page"
                to="/"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Submit a Tool
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
