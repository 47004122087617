import React, { useState } from "react";
import Card from "../Components/Card.js";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import Style from "../Styles/addCsv.module.css";
import Papa from "papaparse";
import useAuthValue from "../../Contexts/Auth.context.js";

function AddCsv() {
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = React.useRef();
  const selectRef = React.useRef();
  const { token } = useAuthValue();

  const convertCSVToJson = (csvData) => {
    const headers = csvData[0];
    const result = [];
    for (let i = 1; i < csvData.length; i++) {
      const obj = {};
      const current = csvData[i];
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = current[j] ? current[j].trim() : null;
      }
      result.push(obj);
    }
    return result;
  };

  const handleCSVInputChange = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: function (results) {
        const arr = results.data;
        const data = convertCSVToJson(arr);
        console.log(data);
        setJsonData([...data]);
      },
    });
    // console.log(jsonData);
  };

  const clearInput = () => {
    setJsonData([]);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (jsonData.length === 0) {
      toast.warn("Please upload CSV File");
    } else {
      let url, reqMethod, msg;
      //updating url based on operations
      if (selectRef.current.value === "Add") {
        url = `${process.env.REACT_APP_API_BASE_URL}api/addCsv`;
        reqMethod = "Post";
        msg = "added";
      } else if (selectRef.current.value === "Update") {
        url = `${process.env.REACT_APP_API_BASE_URL}api/updateCsv`;
        reqMethod = "post";
        msg = "updated";
      } else {
        url = `${process.env.REACT_APP_API_BASE_URL}api/deleteCsv`;
        reqMethod = "post";
        msg = "deleted";
      }

      //set spinner to true
      setIsLoading(true);
      try {
        const response = await fetch(url, {
          method: reqMethod,
          body: JSON.stringify({ products: jsonData }),
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
        const data = await response.json();
        if (data.status) toast.success(`Data ${msg} successfully`);
        else toast.error(data.res);
      } catch (err) {
        toast.error("Server Error");
      } finally {
        //setting spinner to false
        setIsLoading(false);
      }
      clearInput();
    }
  };

  if (isLoading) {
    return (
      <div className={Style.spinner}>
        <Circles color="#212529" />
      </div>
    );
  }

  return (
    <div className={`mx-auto  my-2`}>
      <h5 className="mb-3" style={{ fontWeight: 700, color: "gray" }}>
        Import CSV
      </h5>

      <form>
        <select
          className="form-select mb-3"
          aria-label="Default select example"
          required
          ref={selectRef}
        >
          <option value="Add">Add Data</option>
          <option value="Update">Update Data</option>
          <option value="Delete">Delete Data</option>
        </select>
        <div>
          <input
            className="form-control form-control-lg"
            id="formFileLg"
            type="file"
            ref={inputRef}
            onChange={handleCSVInputChange}
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary my-3"
          onClick={handleUpload}
        >
          Upload
        </button>
      </form>
      <div
        className={`d-flex p-1 mx-auto justify-content-evenly flex-wrap gap-5 w-75`}
      >
        {jsonData
          ? jsonData.map((tool, index) => <Card key={index} toolData={tool} />)
          : null}
      </div>
    </div>
  );
}

export default AddCsv;
