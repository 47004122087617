import Card from "../Components/Card.js";
import useProductValue from "../../Contexts/Product.context.js";

function AllProducts() {
  let { ProductsData } = useProductValue();
  let categories = {};
  for (let p of ProductsData) {
    if (categories[p.Category]) {
      categories[p.Category] += 1;
    } else {
      categories[p.Category] = 1;
    }
  }

  return (
    <>
      <div className="mb-5">
        <h5 className="mb-3" style={{ fontWeight: 700, color: "gray" }}>
          Products
        </h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Category</th>
              <th scope="col">Number of Tools</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(categories).map((c, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{c}</td>
                <td>{categories[c]}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="2"></td>
              <td>Total Tools: {ProductsData.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div
        className={`d-flex p-1 mx-auto my-3 justify-content-evenly flex-wrap gap-5 w-75`}
      >
        {ProductsData.map((tool, index) => (
          <Card key={index} toolData={tool} />
        ))}
      </div> */}
    </>
  );
}

export default AllProducts;
