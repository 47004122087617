import Style from "../Styles/dash.module.css";
import useAuthValue from "../../Contexts/Auth.context";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import React from "react";

function DashData() {
  const [name, setname] = useState();
  const [email, setEmail] = useState();
  const [toolname, setToolname] = useState();
  const [toolUrl, setToolUrl] = useState();
  const [category, setCategory] = useState();
  const [pricing, setPricing] = useState();
  const [description, setDescription] = useState();
  const [id, setId] = useState();

  const {
    getSubscribers,
    submittedTools,
    deleteSubmittedTool,
    acceptSubmittedTool,
    handleUpdateTool,
  } = useAuthValue();

  const handleClick = async () => {
    const err = await getSubscribers();
    if (err) {
      toast.error(err);
    }
  };

  const handleAccept = async (tool) => {
    await acceptSubmittedTool(tool);
  };

  const handleDelete = async (id) => {
    await deleteSubmittedTool(id);
  };

  const handleUpdate = (tool) => {
    setname(tool.name);
    setEmail(tool.email);
    setCategory(tool.category);
    setDescription(tool.description);
    setToolname(tool.toolName);
    setToolUrl(tool.toolUrl);
    setId(tool.id);
  };

  const handleToolName = (e) => {
    setToolname(e.target.value);
  };
  const handleToolUrl = (e) => {
    setToolUrl(e.target.value);
  };
  const handleToolCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleDesciption = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateTool({
      toolName: toolname,
      toolUrl: toolUrl,
      category: category,
      description: description,
      id: id,
    });
  };

  return (
    <div>
      <button
        className={`btn btn-secondary ${Style.btn}`}
        onClick={handleClick}
      >
        Export Subscribers
      </button>
      <div>
        <h1 className="display-6 mt-5" style={{ fontSize: "30px" }}>
          Submitted Tools
        </h1>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Tool Name</th>
              <th scope="col">Tool URL</th>
              <th scope="col">Category</th>
              <th scope="col">Description</th>
              <th scope="col">Pricing</th>
              <th scope="col">User Email</th>
            </tr>
          </thead>
          <tbody>
            {submittedTools?.map((tool, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{tool.toolName}</td>
                <td>{tool.toolUrl}</td>
                <td>{tool.category}</td>
                <td>{tool.description}</td>
                <td>{tool.pricing}</td>
                <td>{tool.email}</td>
                <td>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    <button
                      className="btn btn-secondary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => handleUpdate(tool)}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        handleAccept(tool);
                      }}
                    >
                      Accept
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        handleDelete(tool.id);
                      }}
                    >
                      Decline
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade "
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Update Tool
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="form" onSubmit={handleSubmit}>
                <div className="form mb-3">
                  <label for="toolName">Tool Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="toolName"
                    placeholder="your tool name"
                    value={toolname}
                    onChange={(e) => handleToolName(e)}
                  />
                </div>
                <form className="form mb-3">
                  <label for="floatingURL">Tool URL</label>
                  <input
                    type="url"
                    className="form-control"
                    id="floatingURL"
                    placeholder="your tool's URL"
                    value={toolUrl}
                    onChange={handleToolUrl}
                  />
                </form>
                <form className="form mb-3">
                  <label for="floatingtags">Tool Category</label>
                  <input
                    type="text"
                    className="form-control"
                    id="floatingtags"
                    placeholder="AI Generator, Finance, Gaming , etc"
                    value={category}
                    onChange={handleToolCategory}
                  />
                </form>

                <div className="input-group mb-3">
                  <span className="input-group-text">Tool Description</span>
                  <textarea
                    className="form-control"
                    aria-label="With textarea"
                    placeholder="Write about the features of your tool"
                    style={{ height: "200px" }}
                    value={description}
                    onChange={handleDesciption}
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashData;
