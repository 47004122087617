import React from "react";
import { toast } from "react-toastify";
import useAuthValue from "../../Contexts/Auth.context";

function AddMember() {
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const userRef = React.useRef();
  const passRef = React.useRef();
  const { addMember, allMembers, updateMemberPermissions, setAllMembers } =
    useAuthValue();

  const handleUpdatePermission = async (e, id, type) => {
    e.preventDefault();
    const value = e.target.type.value;
    if (value === "Select") {
      toast.warn("Please select the permissions");
    } else if (value === type) {
      toast.warn("Member already have the required permissions");
    } else {
      try {
        const response = await updateMemberPermissions(id, value);
        if (response.status) {
          toast.success(response.res);
        } else {
          throw new Error("Permissions denied");
        }
        e.target.type.value = "Select";
        const arr = allMembers.map((m) => {
          if (m.id == id) {
            m.type = value;
          }
          return m;
        });
        setAllMembers([...arr]);
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const pass = passRef.current.value;
    const user = userRef.current.value;

    if (!name) {
      toast.warning("Please write member name");
      return;
    } else if (!email) {
      toast.warning("Please write member email");
      return;
    } else if (!pass) {
      toast.warning("Please write member password");
      return;
    }

    const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      email
    );
    if (!isValid) {
      toast.warning("Please write valid email");
    } else {
      const response = await addMember(name, email, pass, user);
      if (response.status) toast.success("Member added successfully");
      else toast.error(response.res);
      if (nameRef.current) nameRef.current.value = "";
      if (emailRef.current) emailRef.current.value = "";
      if (passRef.current) passRef.current.value = "";
    }
  };

  return (
    <>
      <form className={` mx-auto px-1 `} onSubmit={handleSubmit}>
        <h5 className="mb-3" style={{ fontWeight: 700, color: "gray" }}>
          Add Member
        </h5>
        <div className="form-group mb-3">
          <label htmlFor="UserName">Name</label>
          <input
            type="text"
            className="form-control"
            id="UserName"
            placeholder="Enter Name"
            ref={nameRef}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            placeholder="Enter email"
            ref={emailRef}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            type="text"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            ref={passRef}
            required
          />
        </div>
        <select
          className="form-select mb-3"
          aria-label="Default select example"
          ref={userRef}
        >
          <option value="Admin">Administrator</option>
          <option value="Editor">Editor</option>
          <option value="Viewer">Viewer</option>
        </select>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      <div className="my-5">
        <table class="table table-secondary">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Permissions</th>
              <th scope="col">Update Permissions</th>
            </tr>
          </thead>
          <tbody>
            {allMembers?.map((m, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{m.name}</td>
                <td>{m.email}</td>
                <td>{m.type}</td>
                <td>
                  <form
                    className="d-flex flex-wrap gap-2"
                    onSubmit={(e) => handleUpdatePermission(e, m.id, m.type)}
                  >
                    <select
                      class="form-select w-50"
                      aria-label="Default select example"
                      name="type"
                    >
                      <option selected>Select</option>
                      <option value="Admin">Administrator</option>
                      <option value="Editor">Editor</option>
                      <option value="Viewer">Viewer</option>
                    </select>
                    <button className="btn btn-sm btn-success" type="submit">
                      Update
                    </button>
                  </form>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AddMember;
