import Style from "../Styles/FeaturedList.module.css";
import { useState } from "react";
import useProductValue from "../../Contexts/Product.context";
import { toast } from "react-toastify";

function FeaturedList() {
  const [toolname, setToolname] = useState();
  const [toolDesc, setToolDesc] = useState();
  const [image, setImage] = useState();
  const [website, setWebsite] = useState();
  const { removeFeaturedTool, addFeaturedTool, featuredTools } =
    useProductValue();

  const handlename = (e) => {
    setToolname(e.target.value);
  };

  const handleDesc = (e) => {
    setToolDesc(e.target.value);
  };

  const handleImage = (e) => {
    setImage(e.target.value);
  };

  const handleWebsite = (e) => {
    setWebsite(e.target.value);
  };

  const clearStates = () => {
    setImage("");
    setToolDesc("");
    setToolname("");
    setWebsite("");
  };
  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      if (featuredTools.length == 4) {
        toast.info("You can feature maximum 4 tools");
        clearStates();
        return;
      }
      const response = await addFeaturedTool({
        toolName: toolname,
        description: toolDesc,
        imageUrl: image,
        websiteUrl: website,
      });
      if (response.status) {
        toast.success("New Tool added successfully");
        clearStates();
      } else {
        throw new Error("Server error");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleRemove = async (id) => {
    try {
      const response = await removeFeaturedTool(id);
      if (response.status) {
        toast.success("Tool Removed successfully");
      } else {
        throw new Error("Server error");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className={` ${Style.container}`}>
      <h5 className="mb-3" style={{ fontWeight: 700, color: "gray" }}>
        Featured Tools (Fixed)
      </h5>
      <div className={`mx-auto mt-5 ${Style.formContainer}`}>
        <form className="form" onSubmit={handleAdd}>
          <input
            className="form-control"
            placeholder="Tool Name"
            value={toolname}
            onChange={handlename}
            required
          />
          <br />
          <input
            className="form-control"
            placeholder="Tool Description"
            value={toolDesc}
            onChange={handleDesc}
            required
          />
          <br />
          <input
            className="form-control"
            placeholder="Image URL"
            value={image}
            onChange={handleImage}
            required
          />
          <br />
          <input
            className="form-control"
            placeholder="Website URL"
            value={website}
            onChange={handleWebsite}
            required
          />
          <br />
          <button className="btn-primary btn" type="submit">
            Add new tool
          </button>
        </form>
      </div>

      <div class={`my-5 w-50 mx-auto ${Style}`}>
        <table class="table table-light">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Tool Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {featuredTools?.map((tool, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{tool.toolName}</td>
                <td>
                  <div className="d-flex gap-1">
                    <button className="btn btn-success btn-sm">Update</button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleRemove(tool.id)}
                    >
                      Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default FeaturedList;
