import Admin from "./Admin/Components/Dashboard.js";
import Navbar from "./Components/Navbar.js";
import Home from "./Components/Home.js";
import AddCsv from "./Admin/Pages/AddCSV.js";
import AddMember from "./Admin/Pages/AddMember.js";
import DashData from "./Admin/Pages/DashData.js";
import Error from "./Components/error.js";
import AdminError from "./Admin/Components/adminError.js";
import { ProductsContext } from "./Contexts/Product.context.js";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import bootstrap from "bootstrap";
import AllProducts from "./Admin/Pages/AllProducts.js";
import ChangePassword from "./Admin/Pages/ChangePassword.js";
import useAuthValue from "./Contexts/Auth.context.js";
import Signin from "./Admin/Pages/Signin.js";
import FeaturedList from "./Admin/Pages/FeaturedList.js";
import FeaturedCategory from "./Admin/Pages/FeaturedCategory.js";

function App() {
  const { token } = useAuthValue();
  const ProtectRoute = ({ children }) => {
    if (!token) {
      return <Signin />;
    } else {
      return children;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navbar />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Home />,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <ProtectRoute>
          <Admin />
        </ProtectRoute>
      ),
      errorElement: <AdminError />,
      children: [
        {
          index: true,
          element: (
            <ProtectRoute>
              <DashData />
            </ProtectRoute>
          ),
        },
        {
          path: "AddMember",
          element: (
            <ProtectRoute>
              <AddMember />
            </ProtectRoute>
          ),
        },
        {
          path: "ImportCsv",
          element: (
            <ProtectRoute>
              <AddCsv />
            </ProtectRoute>
          ),
        },
        {
          path: "Products",
          element: (
            <ProtectRoute>
              <AllProducts />
            </ProtectRoute>
          ),
        },
        {
          path: "ChangePassword",
          element: (
            <ProtectRoute>
              <ChangePassword />
            </ProtectRoute>
          ),
        },
        {
          path: "FeaturedList",
          element: (
            <ProtectRoute>
              <FeaturedList />
            </ProtectRoute>
          ),
        },
        {
          path: "FeaturedListCategory",
          element: (
            <ProtectRoute>
              <FeaturedCategory />
            </ProtectRoute>
          ),
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <ProductsContext>
        <RouterProvider router={router} />
        <ToastContainer />
      </ProductsContext>
    </div>
  );
}

export default App;
