import React from "react";
import useAuthValue from "../../Contexts/Auth.context";
import Style from "../Styles/signin.module.css";
import { toast } from "react-toastify";

function Signin() {
  const { handleSignin } = useAuthValue();
  const emailRef = React.useRef();
  const passRef = React.useRef();

  const handleClick = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value.trim();
    const password = passRef.current.value;
    if (!password) {
      toast.warn("Please enter your password");
    } else {
      const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        email
      );
      if (isValid) {
        const response = await handleSignin(email, password);
        if (response.status) {
          toast.success("User Logged in");
        } else {
          toast.error(response.err);
        }
      } else {
        toast.warn("Invalid Email");
      }
    }
  };

  return (
    <form className={`mx-auto p-5 my-5 shadow ${Style.form}`}>
      <h2 className={` text-center`}>Sign In</h2>
      <div className="form-group mb-3">
        <label htmlFor="exampleInputEmail1">Email address</label>
        <input
          type="email"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Enter your email"
          ref={emailRef}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="exampleInputPassword1">Password</label>
        <input
          type="password"
          className="form-control mb-1"
          id="exampleInputPassword1"
          placeholder="Enter your Password"
          ref={passRef}
        />
      </div>
      <button
        type="submit"
        className=" btn btn-secondary"
        onClick={(e) => handleClick(e)}
      >
        Login
      </button>
    </form>
  );
}

export default Signin;
