import Style from "../Styles/card.module.css";
import useProductValue from "../Contexts/Product.context";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

function Card({ toolData }) {
  const { ProductsData, setProductsData, handleVoteCount } = useProductValue();

  // these are the images stored in firebase for each category , these will  be used whenever their is no image link for a tool
  const imgLinks = [
    {
      category: "AI Detection",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAI%20detection.png?alt=media&token=289f0980-8661-44fd-af99-69669a752564",
    },
    {
      category: "Aggregators",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAggregators.jpeg?alt=media&token=f44e5c06-7daf-4010-803f-2708cddcfa7e",
    },
    {
      category: "Amazon",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAmazon.jpeg?alt=media&token=618c65b6-79f4-4708-b85e-d7c18377997f",
    },
    {
      category: "Avatar",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAvatar.jpeg?alt=media&token=287dde05-d7a0-4574-a830-c7c6da1da448",
    },
    {
      category: "Chat",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FChat.jpeg?alt=media&token=9e461389-f50f-425e-bb40-cc7f83fe149a",
    },
    {
      category: "Copywriting",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FCopywriting.jpeg?alt=media&token=19ad148f-d059-4186-9c64-db7fcb853fd0",
    },
    {
      category: "Etsy",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FEtsy.jpeg?alt=media&token=fcd8cf01-7cd9-46f3-91c1-8d291c49f8ff",
    },
    {
      category: "Finance",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FFinance.jpg?alt=media&token=2211606d-ebcb-436e-ab50-1844cfa2bf97",
    },
    {
      category: "For Fun",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FFor%20Fun.jpeg?alt=media&token=5e984e5b-a032-4f17-ad24-aca118145fe6",
    },
    {
      category: "Gaming",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGaming.jpeg?alt=media&token=67f53f1f-23ed-4320-9864-0fc3562c3db2",
    },
    {
      category: "Generative Art",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGenerate%20Art.jpeg?alt=media&token=17e4a275-764e-42d3-9c82-0d344b28b47c",
    },
    {
      category: "Generative Code",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGenerative%20Code.jpeg?alt=media&token=76146913-e793-473c-ac12-7d1abca5a766",
    },
    {
      category: "Generative Video",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGenerative%20Video.png?alt=media&token=9135ad05-3bd5-4999-9458-f949ec333f66",
    },
    {
      category: "Image Improvement",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FImage%20Improvement.jpeg?alt=media&token=ecb0833c-04e8-48a9-b473-15ad9498013c",
    },
    {
      category: "Image Scanning",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FImage%20Scanning.jpeg?alt=media&token=e7bff809-f293-475c-9a80-42792ce525f2",
    },
    {
      category: "Inspiration",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FInspiration.jpeg?alt=media&token=04acb0b5-2591-4904-9053-09665ef9da08",
    },
    {
      category: "Keyword Generator",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FKeyword%20Generator.jpeg?alt=media&token=fb0eac95-7ec4-4687-a941-1b9cb74f4dab",
    },
    {
      category: "Marketing",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FMarketing.jpeg?alt=media&token=08b4b861-2aca-4443-ab9d-842af00a822f",
    },
    {
      category: "Motion Capture",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FMotion%20Capture.jpeg?alt=media&token=177c56f6-31f8-4b75-bf87-66cfbf9a76c8",
    },
    {
      category: "Music",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FMusic.jpeg?alt=media&token=060f2fad-7e79-48f6-9a87-bcaa13768fb0",
    },
    {
      category: "Podcasting",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FPodcasting.jpeg?alt=media&token=f45ceaa3-ffa5-4771-b2b2-cbe2dea9a0bf",
    },
    {
      category: "Self Improvement",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FSelf%20Improvement.jpeg?alt=media&token=722088ee-db2e-442e-bfd9-47a4f24b195c",
    },
    {
      category: "Social Media",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FSocial%20Media.jpeg?alt=media&token=a8afece2-cc1e-4813-9281-e980091246e4",
    },
    {
      category: "Text-To-Speech",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FText%20to%20Speech.jpeg?alt=media&token=695a3f36-0274-4598-af35-687031dabdc1",
    },
    {
      category: "Text-To-Video",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2Ftexttovideo.png?alt=media&token=62be93a1-9e01-4cb9-bbd3-726507e2c686",
    },
    {
      category: "Translation",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FTranslation.jpeg?alt=media&token=b4903e0c-ae4d-4de0-b0c6-a95b1634b25b",
    },
    {
      category: "Video Editing",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FVideo%20Editing.jpeg?alt=media&token=22078041-87ee-4024-aaa3-2fd023acac15",
    },
    {
      category: "Voice Modulation",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FVoice%20Modulation.jpeg?alt=media&token=48a43b2e-8973-48f8-89da-0b30a22d5c26",
    },
    {
      category: "Artificial Intelligence",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FArtificial%20Intelligence.jpeg?alt=media&token=b68d2c4a-a7fc-44fa-a5b5-5aca68db297b",
    },
    {
      category: "Audio",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAudio.jpeg?alt=media&token=4324fa0b-1fb3-4207-ac36-26b77bea1fce",
    },
    {
      category: "Chat to",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FChat%20To.jpeg?alt=media&token=23689b3d-e525-4378-868c-5537a99bcfad",
    },
    {
      category: "Chatbots",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FChatbot.jpeg?alt=media&token=aaec2388-5d60-4a3a-8f17-c8254887e13d",
    },
    {
      category: "Customers & Users",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FCustomers%20%26%20Users.jpeg?alt=media&token=631abafe-0b95-4879-bfbd-a9b435e4b0d0",
    },
    {
      category: "Design",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FDesign.jpeg?alt=media&token=a0a1935c-5eed-4d77-a364-2bec41fb2286",
    },
    {
      category: "Education",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FEducation.jpeg?alt=media&token=4b8c5100-12f6-4958-8212-ae2b9394dd4d",
    },
    {
      category: "Image",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FImage.jpeg?alt=media&token=37272b26-310c-4d96-8238-9962692da949",
    },
    {
      category: "No-code",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FNo%20Code.jpeg?alt=media&token=efc42d85-3641-4392-a0f5-97cb04b7909a",
    },
    {
      category: "Operations",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FOperations.jpeg?alt=media&token=e5b20f61-57ce-4858-8d5d-37d7b1626caf",
    },
    { category: "Personal", img: "" },
    {
      category: "Productivity",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FProductivity.jpeg?alt=media&token=3b8a4f34-8e68-470e-afad-9d1223b7a08c",
    },
    {
      category: "Prompt Guides",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FPrompt%20Guides.jpeg?alt=media&token=b239e9e4-9ca1-4e4b-be20-cc20cf4ad69b",
    },
    {
      category: "Research",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FResearch.jpeg?alt=media&token=fd068b12-015d-4560-8d93-5aa4eb74c460",
    },
    {
      category: "Sales",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FSales.jpeg?alt=media&token=f06aa203-d101-4a3b-a9fa-b4ba0e8c4d4c",
    },
    {
      category: "Speech-To-Text",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FSpeech%20To%20Text.jpeg?alt=media&token=0a16c01f-0869-4486-96f1-8f9055cc9ade",
    },
    {
      category: "Video",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FVideo.jpeg?alt=media&token=aaf2878c-b966-41e7-bf55-8e7d002b5c0b",
    },
    {
      category: "Writing",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FWriting.jpeg?alt=media&token=5906cc5b-c0b6-4f6f-98a0-3f31dbb6aac7",
    },
    { category: "None", img: "" },
  ];

  function randomValue(num) {
    return Math.floor(Math.random() * num);
  }

  if (!toolData["image url"] || toolData["image url"].length < 5) {
    const index = imgLinks.findIndex(
      (item) => item.category == toolData["Category"]
    );

    if (index >= 0) {
      // console.log("index: ", index);
      toolData["image url"] = imgLinks[index].img;
      // for multiple images of each category use this code and comment above line , also make changes in imageLinks array, for each category make array of "img" field and add all the links in that array
      // const randomImgIndex = randomValue(imgLinks[index].img.length);
      // toolData["image url"] = imgLinks[index].img[randomImgIndex];
    } else {
      // console.log("-1 index: ", toolData);
      toolData["image url"] = imgLinks[imgLinks.length - 1].img;
    }
  }

  const handleVote = async (id) => {
    const likedItemsString = Cookies.get("likedItems");
    const likedItems = likedItemsString ? JSON.parse(likedItemsString) : [];
    if (!likedItems.includes(id)) {
      // Add the item to liked items
      likedItems.push(id);
      // Set the cookie with an expiration date of next year
      Cookies.set("likedItems", JSON.stringify(likedItems), { expires: 365 });
      const arr = ProductsData.map((p) => {
        if (p.id == id) {
          if (p.votes) {
            p.votes += 1;
          } else {
            p.votes = 1;
          }
        }
        return p;
      });
      setProductsData([...arr]);
      // Perform other actions related to liking the item
      const response = await handleVoteCount(id);
      if (!response.status) {
        toast.error("Server Error");
      }
    }
  };

  return (
    <div
      className={`card shadow ${Style.card}`}
      style={{ width: "18rem", minHeight: "50vh", position: "relative" }}
    >
      <img
        className="card-img-top"
        style={{ height: "150px" }}
        src={toolData["image url"]}
        alt="card cap"
      />
      {toolData.featured ? (
        <p className={`w-25 ${Style.featured}`}>Featured</p>
      ) : null}
      <div className="card-body d-flex align-items-center flex-wrap">
        <div>
          <div className={Style.topContainer}>
            <h5 className="card-title">{toolData["Tool Name"]}</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                minWidth: "40px",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/128/4926/4926585.png"
                className={Style.vote}
                onClick={() => handleVote(toolData.id)}
              />
              <span style={{ fontWeight: 400 }}>
                {toolData["votes"] ? toolData["votes"] : null}
              </span>
            </div>
          </div>

          <p className="card-text">{toolData["Description"]}</p>
        </div>

        <div
          className="d-flex flex-wrap justify-content-between "
          style={{
            width: "100%",
          }}
        >
          <p className="my-auto" style={{ fontWeight: "700", color: "gray" }}>
            {toolData["Category"]}
          </p>
          <a
            href={toolData["Website-URL"]}
            className="btn btn-primary"
            target="_blank"
          >
            Visit Site
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card;
