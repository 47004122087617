import useProductValue from "../Contexts/Product.context";
import Style from "../Styles/filter.module.css";

function Filter() {
  let { handleCategory } = useProductValue();
  const categoryArr = [
    "AI Detection",
    "Aggregators",
    "Amazon",
    "Artificial Intelligence",
    "Audio",
    "Avatar",
    "Chat",
    "Chatbots",
    "Chat to",
    "Code",
    "Copywriting",
    "Customers & Users",
    "Design",
    "Education",
    "Finance",
    "For Fun",
    "Gaming",
    "Generative Art",
    "Generative Code",
    "Generative Video",
    "Image",
    "Image Improvement",
    "Image Scanning",
    "Inspiration",
    "Keyword Generator",
    "Marketing",
    "Motion Capture",
    "Music",
    "No-code",
    "Operations",
    "Personal",
    "Podcasting",
    "Productivity",
    "Prompt Guides",
    "Research",
    "Sales",
    "Self Improvement",
    "Self-Improvement",
    "Social Media",
    "Speech-To-Text",
    "Text-To-Speech",
    "Text-To-Video",
    "Translation",
    "Video",
    "Video Editing",
    "Voice Modulation",
    "Writing",
  ];
  // const categoryArr = [
  //   "AI Detection",
  //   "Aggregators",
  //   "Amazon",
  //   "Avatar",
  //   "Chat",
  //   "Copywriting",
  //   "Etsy",
  //   "Finance",
  //   "For Fun",
  //   "Gaming",
  //   "Generative Art",
  //   "Generative Code",
  //   "Generative Video",
  //   "Image Improvement",
  //   "Image Scanning",
  //   "Inspiration",
  //   "Keyword Generator",
  //   "Marketing",
  //   "Motion Capture",
  //   "Music",
  //   "Podcasting",
  //   "Self Improvement",
  //   "Social Media",
  //   "Text-To-Speech",
  //   "Text-To-Video",
  //   "Translation",
  //   "Video Editing",
  //   "Voice Modulation",
  // ];

  const dividedArr = [];
  let part = [];
  for (let i in categoryArr) {
    part.push(categoryArr[i]);
    if (i > 0 && (Number(i) + 1) % 6 == 0) {
      dividedArr.push(part);
      part = [];
    }
  }
  if (part.length) {
    dividedArr.push(part);
    part = [];
  }

  return (
    <>
      <div className={` mx-auto mb-4 ${Style.filterContainer}`}>
        {/* <h5 className="text-center my-3 ">Filter</h5> */}
        <div class="container text-center">
          <div class="row row-cols-3  row-cols-md-6">
            {categoryArr.map((cat, index) => (
              <div className={` col ${Style.listItem}`}>
                <input
                  type="checkbox"
                  id={index}
                  className={Style.checkbox}
                  onChange={() => {
                    handleCategory(cat);
                  }}
                />
                <label htmlFor={index}>{cat}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
