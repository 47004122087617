import Style from "../Styles/card.module.css";
function Card({ toolData }) {
  const imgLinks = [
    {
      category: "AI Detection",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAI%20detection.png?alt=media&token=289f0980-8661-44fd-af99-69669a752564",
    },
    {
      category: "Aggregators",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAggregators.jpeg?alt=media&token=f44e5c06-7daf-4010-803f-2708cddcfa7e",
    },
    {
      category: "Amazon",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAmazon.jpeg?alt=media&token=618c65b6-79f4-4708-b85e-d7c18377997f",
    },
    {
      category: "Avatar",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FAvatar.jpeg?alt=media&token=287dde05-d7a0-4574-a830-c7c6da1da448",
    },
    {
      category: "Chat",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FChat.jpeg?alt=media&token=9e461389-f50f-425e-bb40-cc7f83fe149a",
    },
    {
      category: "Copywriting",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FCopywriting.jpeg?alt=media&token=19ad148f-d059-4186-9c64-db7fcb853fd0",
    },
    {
      category: "Etsy",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FEtsy.jpeg?alt=media&token=fcd8cf01-7cd9-46f3-91c1-8d291c49f8ff",
    },
    {
      category: "Finance",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FFinance.jpg?alt=media&token=2211606d-ebcb-436e-ab50-1844cfa2bf97",
    },
    {
      category: "For Fun",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FFor%20Fun.jpeg?alt=media&token=5e984e5b-a032-4f17-ad24-aca118145fe6",
    },
    {
      category: "Gaming",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGaming.jpeg?alt=media&token=67f53f1f-23ed-4320-9864-0fc3562c3db2",
    },
    {
      category: "Generative Art",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGenerate%20Art.jpeg?alt=media&token=17e4a275-764e-42d3-9c82-0d344b28b47c",
    },
    {
      category: "Generative Code",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGenerative%20Code.jpeg?alt=media&token=76146913-e793-473c-ac12-7d1abca5a766",
    },
    {
      category: "Generative Video",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FGenerative%20Video.png?alt=media&token=9135ad05-3bd5-4999-9458-f949ec333f66",
    },
    {
      category: "Image Improvement",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FImage%20Improvement.jpeg?alt=media&token=ecb0833c-04e8-48a9-b473-15ad9498013c",
    },
    {
      category: "Image Scanning",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FImage%20Scanning.jpeg?alt=media&token=e7bff809-f293-475c-9a80-42792ce525f2",
    },
    {
      category: "Inspiration",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FInspiration.jpeg?alt=media&token=04acb0b5-2591-4904-9053-09665ef9da08",
    },
    {
      category: "Keyword Generator",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FKeyword%20Generator.jpeg?alt=media&token=fb0eac95-7ec4-4687-a941-1b9cb74f4dab",
    },
    {
      category: "Marketing",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FMarketing.jpeg?alt=media&token=08b4b861-2aca-4443-ab9d-842af00a822f",
    },
    {
      category: "Motion Capture",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FMotion%20Capture.jpeg?alt=media&token=177c56f6-31f8-4b75-bf87-66cfbf9a76c8",
    },
    {
      category: "Music",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FMusic.jpeg?alt=media&token=060f2fad-7e79-48f6-9a87-bcaa13768fb0",
    },
    {
      category: "Podcasting",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FPodcasting.jpeg?alt=media&token=f45ceaa3-ffa5-4771-b2b2-cbe2dea9a0bf",
    },
    {
      category: "Self Improvement",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FSelf%20Improvement.jpeg?alt=media&token=722088ee-db2e-442e-bfd9-47a4f24b195c",
    },
    {
      category: "Social Media",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FSocial%20Media.jpeg?alt=media&token=a8afece2-cc1e-4813-9281-e980091246e4",
    },
    {
      category: "Text-To-Speech",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FText%20to%20Speech.jpeg?alt=media&token=695a3f36-0274-4598-af35-687031dabdc1",
    },
    {
      category: "Text-To-Video",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2Ftexttovideo.png?alt=media&token=62be93a1-9e01-4cb9-bbd3-726507e2c686",
    },
    {
      category: "Translation",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FTranslation.jpeg?alt=media&token=b4903e0c-ae4d-4de0-b0c6-a95b1634b25b",
    },
    {
      category: "Video Editing",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FVideo%20Editing.jpeg?alt=media&token=22078041-87ee-4024-aaa3-2fd023acac15",
    },
    {
      category: "Voice Modulation",
      img: "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/CategoryWiseImages%2FVoice%20Modulation.jpeg?alt=media&token=48a43b2e-8973-48f8-89da-0b30a22d5c26",
    },
    { category: "None", img: "" },
  ];

  // if (!toolData["image url"] || toolData["image url"]?.length < 5) {
  //   const index = imgLinks.findIndex(
  //     (item) => item.category == toolData["Category"]
  //   );

  //   if (index) {
  //     toolData["image url"] = imgLinks[index].img;
  //   } else {
  //     toolData["image url"] = imgLinks[imgLinks.length - 1].img;
  //   }
  // }

  return (
    <div
      className={`card shadow ${Style.card}`}
      style={{ width: "18rem", minHeight: "50vh", position: "relative" }}
    >
      <img
        className="card-img-top"
        style={{ height: "150px" }}
        src={toolData["image url"] || ""}
        alt="card cap"
      />
      <div className="card-body d-flex align-items-center flex-wrap">
        <div>
          <h5 className="card-title">{toolData["Tool Name"]}</h5>
          <p className="card-text">{toolData["Description"]}</p>
        </div>

        <div
          className="d-flex flex-wrap justify-content-between "
          style={{
            width: "100%",
          }}
        >
          <p className="my-auto" style={{ fontWeight: "700", color: "gray" }}>
            {toolData["Category"]}
          </p>
          <a href={toolData["Website-URL"]} class="btn btn-primary">
            Visit Site
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card;
