import Style from "../Styles/featuredlist.module.css";
import FeaturedCard from "./FeaturedCard";
import useProductValue from "../Contexts/Product.context";
function FeaturedListing() {
  const { featuredTools } = useProductValue();
  const toolData = {
    "Tool Name": "Copywriting",
    Description:
      "A tool to automate personalized captions generation for photos.",
    "image url":
      "https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/Images%2FCaption%20My%20Photos.JPG?alt=media&token=ed840e13-7058-4e47-8bf6-5e2ca62c7c0b",
  };

  // return featuredTools.length ? (
  //   <div className={`mx-auto  mb-5 ${Style.featuredContainer}`}>
  //     <div className={Style.innerDiv}>
  //       {featuredTools.length ? (
  //         <FeaturedCard toolData={featuredTools[0]} key={0} />
  //       ) : null}
  //       {featuredTools.length > 1 ? (
  //         <FeaturedCard toolData={featuredTools[1]} key={1} />
  //       ) : null}
  //     </div>
  //     <div className={Style.innerDiv}>
  //       {featuredTools.length > 2 ? (
  //         <FeaturedCard toolData={featuredTools[2]} key={2} />
  //       ) : null}
  //       {featuredTools.length > 3 ? (
  //         <FeaturedCard toolData={featuredTools[3]} key={3} />
  //       ) : null}
  //     </div>
  //   </div>
  // ) : null;

  return (
    <div className={`mx-auto mb-5 p-4  ${Style.featuredContainer} `}>
      <div className={`${Style.childiv}`}>
        <p className={`${Style.featured}`}>Featured</p>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/softwaretools-2233b.appspot.com/o/Images%2Fsellerkinlogo.svg?alt=media&token=16730328-8568-45e0-bf3e-3bb77394842f"
          className={`${Style.image}`}
        />
        <div>
          <h3 style={{ fontWeight: 650 }}>Sellerkin</h3>
          <a
            href="https://sellerkin.com"
            target="_blank"
            style={{ color: "white" }}
          >
            Visit
          </a>
        </div>
      </div>
      {/* *************************** */}
      {/* <div className={`${Style.childiv}`}>
        <img src={sellrkinpic} className={`${Style.image}`} />
        <div>
          <h3
            style={{ fontWeight: 700, fontSize: "22px", textAlign: "center" }}
          >
            Ecom Quik <br />
            Solutions
          </h3>
          <a style={{ color: "white" }}>Visit</a>
        </div>
      </div> */}
    </div>
  );
}

export default FeaturedListing;
