import Style from "../Styles/adminNav.module.css";
import { Outlet, Link } from "react-router-dom";
import useAuthValue from "../../Contexts/Auth.context";

function Admin() {
  const { handleSignout } = useAuthValue();
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/admin">
            Sellerkin
          </Link>

          {/* dashboard dropdown on small screens*/}
          <div className={`dropdown d-md-none ${Style.drpdwn}`}>
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/admin">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="Products">
                  All Products
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="ImportCsv">
                  Import CSV
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="AddMember">
                  Add Member
                </Link>
              </li>
            </ul>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDarkDropdown"
            aria-controls="navbarNavDarkDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul className="navbar-nav ">
              <li className="nav-item dropdown">
                <button
                  className="btn btn-dark dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  User
                </button>
                <ul className="dropdown-menu dropdown-menu-dark">
                  <li>
                    <a className="btn dropdown-item" onClick={handleSignout}>
                      Signout
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-md-none"
                      href="ChangePassword"
                    >
                      Change Password
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Dashboard */}
      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block bg-light shadow sidebar collapse"
            style={{ minHeight: "100vh" }}
          >
            <div className="position-sticky pt-3 sidebar-sticky">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/admin"
                  >
                    <span
                      data-feather="home"
                      className="align-text-bottom"
                    ></span>
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="Products"
                  >
                    <span
                      data-feather="home"
                      className="align-text-bottom"
                    ></span>
                    All Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="FeaturedListCategory">
                    <span
                      data-feather="shopping-cart"
                      className="align-text-bottom"
                    ></span>
                    Featured List (Category)
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="FeaturedList">
                    <span
                      data-feather="shopping-cart"
                      className="align-text-bottom"
                    ></span>
                    Featured List (Fixed)
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="ImportCsv">
                    <span
                      data-feather="file"
                      className="align-text-bottom"
                    ></span>
                    Import CSV
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="AddMember">
                    <span
                      data-feather="shopping-cart"
                      className="align-text-bottom"
                    ></span>
                    Add Member
                  </Link>
                </li>
              </ul>

              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                <span>Settings</span>
                <a
                  className="link-secondary"
                  href="/"
                  aria-label="Add a new report"
                >
                  <span
                    data-feather="plus-circle"
                    className="align-text-bottom"
                  ></span>
                </a>
              </h6>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <Link className="nav-link" to="ChangePassword">
                    <span
                      data-feather="file-text"
                      className="align-text-bottom"
                    ></span>
                    Change Password
                  </Link>
                </li>
              </ul>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Dashboard</h1>
            </div>

            {/* {Add your component here} */}
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}

export default Admin;
