import React from "react";
import { toast } from "react-toastify";
import useAuthValue from "../../Contexts/Auth.context";

function ChangePassword() {
  const { ChangePassword } = useAuthValue();
  const newpRef = React.useRef();
  const confirmpRef = React.useRef();

  const clearForm = () => {
    newpRef.current.value = "";
    confirmpRef.current.value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newp = newpRef.current.value;
    const confirmp = confirmpRef.current.value;
    if (!newp) toast.warning("Please enter new password");
    else if (newp != confirmp) {
      toast.warning("Passwords doesn't match");
      clearForm();
    } else {
      const response = await ChangePassword(newp);
      if (response.status) toast.success(response.res);
      else toast.error(response.res);
      clearForm();
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <h5 className="mb-3" style={{ fontWeight: 700, color: "gray" }}>
        Change Password
      </h5>
      <div className="mb-3">
        <label htmlFo="NewPassword" className="form-label">
          New Password
        </label>
        <input
          type="text"
          className="form-control"
          id="NewPassword"
          ref={newpRef}
        />
      </div>
      <div className="mb-3">
        <label htmlFo="exampleInputPassword1" className="form-label">
          Confirm Password
        </label>
        <input
          type="text"
          className="form-control"
          id="exampleInputPassword1"
          ref={confirmpRef}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Update
      </button>
    </form>
  );
}

export default ChangePassword;
