import Card from "./Card";
import Filter from "./Filter";
import Style from "../Styles/home.module.css";
import { Circles, Oval } from "react-loader-spinner";
import useProductValue from "../Contexts/Product.context.js";
import FeaturedListing from "./FeaturedListing.js";
import { useState } from "react";
import { toast } from "react-toastify";

function Home() {
  let {
    ProductsData,
    filterCategory,
    handleSearch,
    isLoading,
    isError,
    LoadMoreProducts,
    search,
    setSearch,
  } = useProductValue();
  // const [search, setSearch] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const searchedTerm = e.target.search.value;
    e.target.search.value = "";
    if (searchedTerm?.length == 0) {
      return;
    }
    const response = await handleSearch(searchedTerm);
    if (!response.err) {
      if (!response.status) {
        toast.warn("No tools found. Try a different keyword");
      }
    } else {
      toast.error(response.msg);
    }
    // console.log(response);
  };

  return (
    <>
      <div className={`mb-5 mx-auto ${Style.searchDiv}`}>
        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            name="search"
            className={Style.searchInput}
            placeholder="Search..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <button className="btn btn-primary btn-lg" type="submit">
            Search
          </button>
        </form>
      </div>
      <Filter />
      <FeaturedListing />
      <div
        className={`d-flex p-1 mx-auto justify-content-evenly flex-wrap gap-5 w-75 ${Style.cardContianer}`}
      >
        {isError ? (
          <div className="my-5">
            <h1>{isError}</h1>{" "}
            <p className="text-center">(Please wait we are looking into it)</p>
          </div>
        ) : isLoading ? (
          <div className={Style.spinner}>
            <Circles color="#0B5ED7" />
          </div>
        ) : (
          <>
            {filterCategory?.length > 0 ? (
              <>
                {ProductsData.map((tool, index) => {
                  if (
                    filterCategory.find(
                      (c) =>
                        c?.trim().toLowerCase() ===
                          tool.Category?.trim().toLowerCase() && tool.featured
                    )
                  ) {
                    return <Card key={index} toolData={tool} />;
                  } else return null;
                })}
                {ProductsData.map((tool, index) => {
                  if (
                    filterCategory.find(
                      (c) =>
                        !tool?.featured &&
                        c?.trim().toLowerCase() ===
                          tool.Category?.trim().toLowerCase()
                    )
                  ) {
                    return <Card key={index} toolData={tool} />;
                  } else return null;
                })}
              </>
            ) : (
              ProductsData.map((tool, index) => {
                return <Card key={index} toolData={tool} />;
              })
            )}
            {/* {search && ProductsData.length == 0 ? (
              <h5>No tools found. Try a different keyword!</h5>
            ) : null} */}
            {/* {filterCategory.length == 0 && !search ? (
              <div
                className={`mx-auto w-25 d-flex justify-content-center my-5 ${Style.loadmore}`}
              >
                <button
                  className="btn btn-outline-dark btn-lg"
                  style={{ maxHeight: "50px" }}
                  onClick={LoadMoreProducts}
                >
                  Load more
                </button>
              </div>
            ) : null} */}
          </>
        )}
      </div>
      {filterCategory.length == 0 && !search && ProductsData.length > 0 ? (
        <div
          className={`mx-auto w-25 d-flex justify-content-center my-5 ${Style.loadmore}`}
        >
          <button
            className="btn btn-outline-dark btn-lg"
            style={{ maxHeight: "50px" }}
            onClick={LoadMoreProducts}
          >
            Load more
          </button>
        </div>
      ) : null}
    </>
  );
}

export default Home;
