import { useState } from "react";
import useProductValue from "../../Contexts/Product.context";
import { toast } from "react-toastify";

function FeaturedCategory() {
  const [search, setSearch] = useState();
  const { handleSearchTool, handleCategoryFeatured } = useProductValue();
  const [tool, setTool] = useState([]);
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await handleSearchTool(search);
      if (response.status) {
        setTool([...response.res]);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSearch("");
    }
  };

  const handleFeature = async (id) => {
    try {
      const response = await handleCategoryFeatured(id);
      if (response.status) {
        const arr = tool.map((t) => {
          if (t.id == id) {
            if (t.featured) {
              t.featured = false;
            } else {
              t.featured = true;
            }
          }
          return t;
        });
        setTool([...arr]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      <h5 className="mb-3" style={{ fontWeight: 700, color: "gray" }}>
        Featured Tools (Category)
      </h5>
      <form className="form w-50 mx-auto my-5" onSubmit={handleSearch}>
        <input
          type="search"
          className="form-control mb-3 p-2"
          placeholder="Enter Tool Name..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          required
        />
        <button className="btn  d-block mx-auto btn-primary" type="submit">
          Search Tool
        </button>
      </form>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Tool Name</th>
            <th scope="col">Category</th>
            <th scope="col">Featured</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {tool?.map((t, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{t["Tool Name"]}</td>
              <td>{t["Category"]}</td>
              <td>{t.featured ? "YES" : "NO"}</td>
              <td>
                {t.featured ? (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleFeature(t.id)}
                  >
                    Unfeature
                  </button>
                ) : (
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => handleFeature(t.id)}
                  >
                    Feature
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FeaturedCategory;
